import React, { useState, useContext, useRef } from 'react'
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom'

export default function ClientsTable(props) {
    const { clients } = props;
    const [sortConfig, setSortConfig] = useState({key: 'name', direction:'ascending'});
    const navigate = useNavigate();

    // DOM References
    const ref_customer_number = useRef();
    const ref_name = useRef();
    const ref_epic = useRef();
    const ref_platform = useRef();
    
    let sortedClients = [...clients];

    sortedClients.sort((a, b) => {
        if(sortConfig.key === 'customer_number'){
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }else{
            if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const requestSort = key => {
        // Remove all existing table sorting arrows
        ref_customer_number.current.className = '';
        ref_name.current.className = '';
        ref_epic.current.className = '';
        ref_platform.current.className = '';

        let direction = 'ascending';
        // Eval to allow use of dynamic variable name
        eval('ref_' + key + '.current.className="arrow-up"');

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
            eval('ref_' + key + '.current.className="arrow-down"');
        }
        setSortConfig({ key, direction });
    }
  
    return (
        <table className="clients-table mt15" cellSpacing="0" cellPadding="0">
        <thead>
            <tr>
                <th onClick={() => requestSort('customer_number')} title="Sort by Customer #">Customer # <span ref={ref_customer_number}></span></th>
                <th onClick={() => requestSort('name')} title="Sort by Portal Name">Portal Name <span ref={ref_name} className="arrow-up"></span></th>
                <th onClick={() => requestSort('epic')} title="Sort by JIRA EPIC">JIRA EPIC <span ref={ref_epic}></span></th>
                <th onClick={() => requestSort('platform')} title="Sort by Platform">Platform <span ref={ref_platform}></span></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {sortedClients.map(client => {
            return (
            <tr key={client.customer_number} title={"Click to view/edit " + client.customer_number} onClick={() => navigate('/portal?id=' + client.customer_number)} >
                <td data-label="Customer #">{client.customer_number}</td>
                <td data-label="Portal">{client.name}</td>
                <td data-label="EPIC">{client.epic}</td>
                <td data-label="Platform">{client.platform}</td>

                <td>
                    <div className="edit-icon" title="Edit request" onClick={() => navigate('/portal?id=' + client.id)}></div>
                </td>
            </tr>
            )
        })}
        
        </tbody>
        </table>
  )
}
