import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import UsersTable from '../Components/UsersTable';

export default function Users() {
    const { user, setUser } = useContext(UserContext);
    const [ users, setUsers ] = useState(null)
    const [ original, setOriginal ] = useState(null)
    const [ search, setSearch ] = useState('')

    // DOM References
    const refReturnTop = useRef();
    const refSearch = useRef();
    const refClearSearch = useRef();
    
    async function handleChange(e){
        e.preventDefault();
        // Adjust width of search box with CSS transition
        if(e.target.value !== ''){
            refSearch.current.style.width = '250px';
            refClearSearch.current.style.display = 'inline';
        }else{
            refSearch.current.style.width = '225px';
            refClearSearch.current.style.display = 'none';
        }

        // Filter result set in real-time
        if(e.target.value === ''){
            setUsers(original);
        }else{
            const results = original.filter(item => {
                if(item.id){
                    if(item.id.toString().indexOf(e.target.value) !== -1) return item;
                }
                if(item.username){
                    if(item.username.toLowerCase().indexOf(e.target.value) !== -1) return item;
                }
                if(item.fname){
                    if(item.fname.toLowerCase().indexOf(e.target.value) !== -1) return item;
                }
                if(item.lname){
                    if(item.lname.toLowerCase().indexOf(e.target.value) !== -1) return item;
                }
                if(item.company){
                    if(item.company.toLowerCase().indexOf(e.target.value) !== -1) return item;
                }
                if(item.epic){
                    if(item.epic.toLowerCase().indexOf(e.target.value) !== -1) return true;
                }
            })
            
            setUsers(results)
        }

        setSearch(e.target.value)
        
    }
                        
    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
            };

            let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                const requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };
        
                response = await fetch('https://picform.lahlouhonline.com/api/users', requestOptions)

                data = await response.json()

                if(data){
                    setUsers(data)
                    setOriginal(data)
                }
            }else{
                await setUser(null)
            }
        }

        verifyUser();
        
    }, [])

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    async function clearSearch(){
        setSearch('');
        refSearch.current.style.width = '225px';
        refClearSearch.current.style.display = 'none';
        setUsers(original);
    }

    if(user && user.role !== 'admin'){
        return (
        <div className="main">
            <div className="main-content">
                <div className="mt15 center">
                    <h3 style={{fontWeight:'400'}}>Unauthorized Access</h3>
                </div>
            </div>
        </div>
        )
    }

    if(user && user.role === 'admin'){ 
        return (
        <>
        <div className="main">
        <div className="main-content">
                <h1 className="mt10">User Management</h1>

                {users === null
                ?
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No users to display.</h3></div>
                :
                <>

                <div className="search-flex-wrapper">
                    <div>&nbsp;</div>
                    <div className="search-wrapper">
                        <input
                            className="requests-search center"
                            name="new-search"
                            ref={refSearch}
                            type="text"
                            placeholder="Search for users..."
                            value={search}
                            onChange={handleChange}
                        />
                        <span
                            className="clear-search"
                            ref={refClearSearch}
                            onClick={clearSearch}
                            title="Clear Search"
                        >x</span>
                    </div>

                    <div>
                        <Link to="/add-user">
                            <button className="primary-button" title="Add User">
                                +
                            </button>
                        </Link>
                    </div>
                </div>

                </>
                }
                {users != null && users.length > 0
                ? <UsersTable users={users} />
                : <div className="mt20 center" style={{fontSize:'18px'}}>No results found for the current search.</div>
            }
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>
        </>
        )
    }
}
